import {
  KeyboardSensor,
  PointerSensor,
  SensorDescriptor,
  SensorOptions,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { IPlace, LatLon } from '@truckmap/common';
import { useMapControllerContext } from 'components/RoutePlanner/RouteBuilder/MapControllerContext';
import {
  useWaypointsActionsContext,
  useWaypointsValueContext
} from 'components/RoutePlanner/RouteBuilder/WaypointsContext';
import { PingEventGroups } from 'hooks/useRoutePlanner/useWaypoints';
import { produce } from 'immer';
import { pingEvent } from 'lib/analytics/event';
import { isSupportedCountry } from 'lib/map/getGeolocation';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { truckRouteVisibleAtom, Waypoint } from 'recoil/routePlanner';
import { DefaultLocationWaypoint, routePlannerAtom } from 'recoil/routePlanner/routePlannerAtom';

interface IndexWithWaypoint {
  index: number;
  waypoint: Waypoint | IPlace;
}

export type RouteHandlers = {
  sensors: SensorDescriptor<SensorOptions>[];
  handleDragStart: (e) => void;
  handleDragEnd: (e) => void;
  activeIndex: number;
  lastIndex: number;
  waypointIds: string[];
  activeWaypoint: Waypoint;
  waypointCount: number;
  waypointOrigin: Waypoint;
  waypointDestination: Waypoint;
  handleExpandWaypoints: (e) => void;
  handleAddWaypoint: (waypoint: Waypoint) => void;
  handleInsertWaypoint: (waypoint: Waypoint, newWaypointIndex: number) => void;
  handleSwapOriginDestination: (e) => void;
  handleDeleteWaypoint: (e: GenericEvent, { index, waypoint }: IndexWithWaypoint) => void;
  handleSetWaypointValue: (waypoint: Waypoint, customIndex?: number) => void;
  showDeleteWaypointButton: (index: number, waypoint: Waypoint) => boolean;
  activeId: string;
  itemHeight: number;
  setFirstWaypointDefault: ({ latitude, longitude }: Required<LatLon>) => void;
};

export const useRouteBuilderHandlers = (): RouteHandlers => {
  const waypoints = useWaypointsValueContext();
  const mapController = useMapControllerContext();
  const [, setRoutePlannerState] = useRecoilState(routePlannerAtom(mapController));

  const [
    { sortWaypoints, addWaypoint, setWaypoint, setEmptyWaypoint, removeWaypoint, onWaypointChange },
    {
      toggleEditWaypoints,
      hasExpandedWaypoints,
      toggleAddWaypoint,
      isAddingWaypoint,
      swapOriginDestinationWaypoints
    }
  ] = useWaypointsActionsContext();

  // used for transitions
  const itemHeight = 52;

  const [isTruckRouteVisible, setTruckRouteVisible] = useRecoilState(
    truckRouteVisibleAtom(mapController)
  );

  const [activeId, setActiveId] = useState<null | string>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        sortWaypoints(active.id, over.id);
      }

      setActiveId(null);

      pingEvent('DRAG_END', {
        component: PingEventGroups.ROUTE_PLANNER
      });
    },
    [sortWaypoints, setActiveId]
  );

  const handleDragStart = useCallback(
    (event) => {
      const { active } = event;
      setActiveId(active.id);

      pingEvent('DRAG_START', {
        component: PingEventGroups.ROUTE_PLANNER
      });
    },
    [setActiveId]
  );

  const lastIndex = waypoints.length - 1 > 0 ? waypoints.length - 1 : 1;

  const activeIndex = useMemo(
    () => waypoints.findIndex((w) => w.id === activeId),
    [waypoints, activeId]
  );

  const waypointIds = useMemo(
    () => waypoints.filter((w) => Boolean(w?.id)).map((w) => w.id),
    [waypoints]
  );

  const activeWaypointIndex = useMemo(
    () => waypoints.findIndex((w) => w.id === activeId),
    [waypoints, activeId]
  );
  const activeWaypoint = useMemo(
    () => waypoints[activeWaypointIndex],
    [waypoints, activeWaypointIndex]
  );

  const waypointOrigin = useMemo(() => waypoints.length && waypoints[0], [waypoints]);

  const waypointDestination = useMemo(() => {
    return waypoints.length && waypoints[waypoints.length - 1];
  }, [waypoints]);

  const waypointCount = useMemo(
    // When adding a waypoint, there is an empty waypoint field
    () => (isAddingWaypoint ? waypoints.length - 1 : waypoints.length - 2),
    [isAddingWaypoint, waypoints]
  );

  const setFirstWaypointDefault = useCallback(
    ({ longitude, latitude }) => {
      if (
        isSupportedCountry() &&
        waypointOrigin &&
        !(waypointOrigin as DefaultLocationWaypoint).objectType
      ) {
        setWaypoint(0, {
          lat: latitude,
          lon: longitude,
          objectType: 'currentLocation'
        } as DefaultLocationWaypoint);
      }
    },
    [waypointOrigin]
  );

  const handleExpandWaypoints = useCallback(
    (e) => {
      e?.preventDefault();
      isTruckRouteVisible && setTruckRouteVisible(false);
      toggleEditWaypoints(!hasExpandedWaypoints);

      pingEvent('VIEW_WAYPOINTS', {
        component: PingEventGroups.ROUTE_PLANNER
      });
    },
    [isTruckRouteVisible, setTruckRouteVisible, toggleEditWaypoints, hasExpandedWaypoints]
  );

  const handleAddWaypoint = useCallback(
    (waypoint) => {
      const { index } = addWaypoint();
      setWaypoint(index, waypoint);
      isAddingWaypoint && toggleAddWaypoint(false);

      pingEvent('ADD_WAYPOINT', {
        component: PingEventGroups.ROUTE_PLANNER
      });
    },
    [addWaypoint, setWaypoint, isAddingWaypoint, toggleAddWaypoint]
  );

  const handleInsertWaypoint = useCallback(
    (waypoint: Waypoint, newWaypointIndex: number) => {
      const newWaypoint = addWaypoint(waypoint, newWaypointIndex);
      setWaypoint(newWaypointIndex, waypoint);

      pingEvent('INSERT_WAYPOINT', {
        component: PingEventGroups.ROUTE_PLANNER
      });
      return newWaypoint;
    },
    [addWaypoint, setWaypoint]
  );

  const handleSetWaypointValue = useCallback(
    (currentWaypoint, index) => {
      setWaypoint(index, currentWaypoint);
    },
    [setWaypoint]
  );

  const handleSwapOriginDestination = useCallback(
    (e) => {
      e?.preventDefault();
      e?.stopPropagation();

      pingEvent('SWAP_ORIGIN_DESTINATION', {
        component: PingEventGroups.ROUTE_PLANNER
      });

      return swapOriginDestinationWaypoints();
    },
    [swapOriginDestinationWaypoints]
  );

  const handleDeleteWaypoint = useCallback(
    (e, { index, waypoint }) => {
      e?.preventDefault();

      // TODO dispatch has one less waypoint here memo discrepancy somewhere likely
      pingEvent('DELETE_WAYPOINT', {
        component: PingEventGroups.ROUTE_PLANNER,
        isFirstWaypoint: index === 0
      });

      if (waypoints.length > 2) {
        const newWaypointsLength = waypoints.length - 1;

        hasExpandedWaypoints &&
          !isAddingWaypoint &&
          newWaypointsLength === 2 &&
          toggleEditWaypoints(false);

        removeWaypoint(waypoint);

        return;
      }

      setEmptyWaypoint(index);

      // Removing either origin or destination needs to clear the trip
      setRoutePlannerState(
        produce((draft) => {
          draft.tripId = undefined;
          draft.currentTrip = undefined;
        })
      );

      onWaypointChange();

      return;
    },
    [
      waypoints,
      removeWaypoint,
      setEmptyWaypoint,
      toggleEditWaypoints,
      isAddingWaypoint,
      hasExpandedWaypoints
    ]
  );

  const isFirstWaypoint = (index, waypoint) =>
    index === 0 && waypointCount === 0 && (waypoint as IPlace)?.objectType === 'currentLocation';

  const isNewWaypoint = (index) => index === lastIndex + 1;

  const showDeleteWaypointButton = (index, waypoint) =>
    ((waypoint as IPlace)?.objectType && !isFirstWaypoint(index, waypoint)) || isNewWaypoint(index);

  return {
    sensors,
    handleDragStart,
    handleDragEnd,
    activeIndex,
    lastIndex,
    waypointIds,
    activeWaypoint,
    activeId,
    waypointCount,
    waypointOrigin,
    waypointDestination,
    handleExpandWaypoints,
    handleAddWaypoint,
    handleInsertWaypoint,
    handleSwapOriginDestination,
    handleDeleteWaypoint,
    handleSetWaypointValue,
    setFirstWaypointDefault,
    showDeleteWaypointButton,
    itemHeight
  };
};
