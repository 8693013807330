import { useBreakpoints } from 'hooks/useUI/useBreakpoints';
import { makeStyles } from 'lib/makeStyles';
import { memo, useMemo } from 'react';
import { truckMapConfig } from 'truckMapConfig';

import { RouteMap } from './RouteMap';

export const NAVIGATION_HEIGHT = truckMapConfig.isTenstreet ? 0 : 80;
export const ROUTE_PLANNER_HEIGHT_CALC = 'calc(100vh - 80px)';
export const ROUTE_PLANNER_CARD_WIDTH = 388;
export const ROUTE_PLANNER_CARD_STYLE = { width: ROUTE_PLANNER_CARD_WIDTH };
export const ROUTE_PLANNER_TOP_PADDING = 6;
export const ROUTE_PLANNER_TOP_MOBILE = truckMapConfig.isTenstreet
  ? ROUTE_PLANNER_TOP_PADDING
  : -16;
export const ROUTE_PLANNER_TABS_HEIGHT = 34;
export const TENSTREET_MODAL_WIDTH = ROUTE_PLANNER_CARD_WIDTH - 24;

const useStyles = makeStyles({
  root: 'relative'
});

export const RoutePlanner = memo(() => {
  const styles = useStyles();
  const { currentBreakpoint, breakpoints } = useBreakpoints();

  const isMobile = useMemo(
    () => currentBreakpoint === breakpoints.XS || currentBreakpoint === breakpoints.SM,
    [currentBreakpoint]
  );

  return (
    <div
      className={styles.root}
      style={{
        height: isMobile || truckMapConfig.isTenstreet ? '100vh' : ROUTE_PLANNER_HEIGHT_CALC
      }}>
      <RouteMap />
    </div>
  );
});

RoutePlanner.displayName = 'RoutePlanner';
