'use client';
import { useClientSideEffect } from 'hooks/useClientSideEffect';
import { loadScript } from 'lib/loadScript';
import { useLayoutEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { driftWidgetAtom } from 'recoil/driftWidget';
import { truckMapConfig } from 'truckMapConfig';

export const DRIFT_FRAME_CONTROLLER_ID = 'drift-frame-controller';
export const DRIFT_FRAME_CHAT_ID = 'drift-frame-chat';
export const DRIFT_HIDE_CLASS = 'hide-drift';

let driftLoaderLock: boolean;
let driftLoaded: boolean;

const updateDriftWidgetClassList = (hideWidget: boolean) => {
  const driftFrameController = document.getElementById(DRIFT_FRAME_CONTROLLER_ID);
  const driftFrameChat = document.getElementById(DRIFT_FRAME_CHAT_ID);

  if (hideWidget) {
    driftFrameChat?.classList.add(DRIFT_HIDE_CLASS);
    return driftFrameController?.classList.add(DRIFT_HIDE_CLASS);
  }

  driftFrameChat?.classList.remove(DRIFT_HIDE_CLASS);
  driftFrameController?.classList.remove(DRIFT_HIDE_CLASS);
};

/**
 * Add and remove CSS class for controlling Drift widget visibility
 * @returns useLayoutEffect to add/remove CSS class
 */
export const useDriftWidgetScript = () => {
  if (
    truckMapConfig.isSearchBot ||
    truckMapConfig.isTenstreet ||
    truckMapConfig.env !== 'production'
  )
    return;

  const hideDriftWidget = useRecoilValue(driftWidgetAtom);

  useClientSideEffect(() => {
    if (!truckMapConfig.isSearchBot && !driftLoaderLock) {
      driftLoaderLock = true;

      // https://web.dev/third-party-facades/
      const facadeEvent = 'mousemove';
      const scriptFacade = () => {
        loadScript('/scripts/drift.js').then(() => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          driftLoaded = true;
        });
        document.removeEventListener(facadeEvent, scriptFacade);
      };

      document.addEventListener(facadeEvent, scriptFacade);
    }
  });

  return useLayoutEffect(() => {
    if (driftLoaded) {
      updateDriftWidgetClassList(hideDriftWidget);
    }
  }, [hideDriftWidget]);
};

/**
 * Hide Drift widget
 * @param hideWidget boolean
 * @returns useLayoutEffect to add/remove CSS class
 */
export const useHideDriftWidget = (hideWidget = true) => {
  const setDriftWidgetVisibility = useSetRecoilState(driftWidgetAtom);

  return useLayoutEffect(() => {
    updateDriftWidgetClassList(hideWidget);
    setDriftWidgetVisibility(hideWidget);
  }, [setDriftWidgetVisibility, hideWidget]);
};
