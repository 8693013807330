import {
  RouteHandlers,
  useRouteBuilderHandlers
} from 'hooks/useRoutePlanner/useRouteBuilderHandlers';
import React, { createContext } from 'react';

type ProviderProps = { children: React.ReactNode };

const RouteBuilderHandlersContext = createContext<RouteHandlers | null>(null);

const RouteBuilderHandlersProvider = ({ children }: ProviderProps) => {
  const routeHandlers = useRouteBuilderHandlers();

  return (
    <RouteBuilderHandlersContext.Provider value={routeHandlers}>
      {children}
    </RouteBuilderHandlersContext.Provider>
  );
};

const useRouteBuilderHandlersContext = () => {
  const context = React.useContext(RouteBuilderHandlersContext);
  if (!context) {
    throw new Error(
      'useRouteBuilderHandlersContext must be used within a RouteBuilderHandlersProvider'
    );
  }
  return context;
};

export { RouteBuilderHandlersProvider, useRouteBuilderHandlersContext };
